import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';
import BodyClassName from 'react-body-classname';
import SubscribeForm from '../components/Form/SubscribeForm';
import BackgroundImage from 'gatsby-background-image';
import Image from 'gatsby-image';
import renderHTML from 'htmr';

const SubscribePage = (props) => {
  const {
    data: { bgSubscribe, bgThankYou },
  } = props;

  const [subscribeFormSubmitted, setSubscribeFormSubmitted] = useState(false);

  const onSubscribe = (data) => {
    setSubscribeFormSubmitted(data);
  };

  return (
    <BodyClassName className="body-light page-subscribe">
      <DefaultLayoutComponent title="Subscribe">
        <div className="site-main">
          <BackgroundImage
            Tag="section"
            className="page-banner bgImage"
            fluid={bgSubscribe.childImageSharp.fluid}
            preserveStackingContext
          >
            <div className="overlay" />
            <div className="wrapper wrapper-xl pd-x-md">
              <div className="pd-x-md pb-container">
                <h3 className="pb-title">Subscribe</h3>
              </div>
              <div className="section bg-light pd-t-0 subscribe">
                <div className="wrapper wrapper-lg pd-x-sm">
                  <div className="breadcrumb pd-x-sm"></div>
                  <div className="subscribe-block pd-x-sm">
                    {subscribeFormSubmitted && subscribeFormSubmitted.result === 'success' ? (
                      <div className="thanks-image-wrapper">
                        <Image fluid={bgThankYou.childImageSharp.fluid} />
                        <div className="texts">
                          <h1>Thank you for subscribing!</h1>
                          <p>We are excited to bring History to life, right to your inbox.</p>
                        </div>
                      </div>
                    ) : (
                      <>
                        <h4>Keep in touch</h4>
                        <p className="p-lg">Fill in this form to keep up to date with everything we are doing.</p>
                        <SubscribeForm page="subscribe" callback={onSubscribe} />
                        <div className="note-form">
                          {subscribeFormSubmitted && subscribeFormSubmitted.result === 'error'
                            ? renderHTML(subscribeFormSubmitted.msg)
                            : null}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </BackgroundImage>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  {
    bgSubscribe: file(relativePath: { eq: "bg-subscribe.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bgThankYou: file(relativePath: { eq: "bg-thank-you-subscribe.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default SubscribePage;
